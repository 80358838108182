import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const ModNursePerformOrderUX = (props: any) => {
    return(
      <div
        id="ModNursePerformOrderUX-div-0"
        style={{width: "100%", height: "100%"}}>
        <div
          id="ModNursePerformOrderUX-div-1"
          style={{padding: "15px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          
          <div
            id="ModNursePerformOrderUX-div-2"
            style={{fontWeight: "bold", padding: "0px 15px"}}>
            Time
          </div>
          <TimeFreeTextBox24
            autoFocus={false}
            className="time-custom-react-picker"
            id="ModNursePerformOrderUX-TimeFreeTextBox24-9"
            onChange={props.onTimeChange}
            value={props.selectedTime}>
          </TimeFreeTextBox24>
          <div
            id="ModNursePerformOrderUX-div-3"
            style={{fontWeight: "bold", padding: "0px 15px"}}>
            Date
          </div>
          <DateTextBox
            id="ModNursePerformOrderUX-DateTextBox-6"
            onChange={props.onChangeDate}
            style={{transform: "scale(0.8)"}}
            value={props.selectedDate}>
          </DateTextBox>
          <div
            id="ModNursePerformOrderUX-div-4"
            style={{fontWeight: "bold", padding: "0px 15px"}}>
            Nurse
          </div>
          <div
            id="ModNursePerformOrderUX-div-8"
            style={{transform: "scale(0.9)"}}>
            {props.employeeCode}
          </div>
          <Button
            color="green"
            id="ModNursePerformOrderUX-Button-5"
            onClick={props.onSave}
            size="mini"
            style={{margin: "0px 15px"}}>
            SAVE
          </Button>
        </div>
      </div>
    )
}


export default ModNursePerformOrderUX

export const screenPropsDefault = {}

/* Date Time : Thu Jan 30 2025 14:41:54 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", padding: \"0px 15px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Date"
        },
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-div-3"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", padding: \"0px 15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Nurse"
        },
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-div-4"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", padding: \"0px 15px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-Button-5"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 15px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 6,
      "name": "DateTextBox",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-DateTextBox-6"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "style": {
          "type": "code",
          "value": "{transform: \"scale(0.8)\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDate"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.employeeCode"
        },
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-div-8"
        },
        "style": {
          "type": "code",
          "value": "{transform: \"scale(0.9)\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 9,
      "name": "TimeFreeTextBox24",
      "parent": 1,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "id": {
          "type": "value",
          "value": "ModNursePerformOrderUX-TimeFreeTextBox24-9"
        },
        "onChange": {
          "type": "code",
          "value": "props.onTimeChange"
        },
        "value": {
          "type": "code",
          "value": "props.selectedTime"
        }
      },
      "seq": 4,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModNursePerformOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 55
}

*********************************************************************************** */
